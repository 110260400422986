import { render, staticRenderFns } from "./todayPreVisit.vue?vue&type=template&id=345c673b&scoped=true&"
import script from "./todayPreVisit.vue?vue&type=script&lang=js&"
export * from "./todayPreVisit.vue?vue&type=script&lang=js&"
import style0 from "./todayPreVisit.vue?vue&type=style&index=0&id=345c673b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345c673b",
  null
  
)

export default component.exports